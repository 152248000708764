body {
  margin: 0;
}

code {
  font-family: opensans, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.frontendModal {
  position: fixed !important;
  top: 30% !important;
  width: 484px !important;
  padding: 12px;
  z-index: 100 !important;
  right: 35% !important;
}

@media only screen and (max-width: 600px) {
  .frontendModal {
    z-index: 100 !important;
    right: 15% !important;
  }
}

@font-face {
  font-family: "opensans";
  src: local("opensans"), url(./fonts/opensans.ttf) format("truetype");
}

@font-face {
  font-family: "inter";
  src: local("inter"), url(./fonts/Inter-VariableFont_slnt,wght.ttf) format("truetype");
}

@font-face {
  font-family: "burton";
  src: local("burton"), url(./fonts/BurtonsDreamsPro-R1rE.ttf) format("truetype");
}

@font-face {
  font-family: 'Libre Baskerville', serif;
  src: local("baskerville"), url(./fonts/LibreBaskerville-Regular.ttf) format("truetype");
}

h1 {
  font-family: inter;
  font-size: 60px;
}
