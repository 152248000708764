::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4) !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.4) !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App {
  text-align: center;
  /* font-family: inter; */
}

.time-expired {
  color: red !important;
}

.time-expired-soon {
  color: orange !important;
}

.user-reminder-input {
  min-width: 300px;
  max-width: 900px;
  height: 48px;
  text-align: center;
  color: black !important;
  border-radius: 0 !important;
  margin-top: 12px;
}

.reminder-input-section {
  max-width: 500px;
  margin: auto !important;
}

.disbursement-header-image {
  width: 100%;
  max-width: 600px;
}

.notification-section {
  min-width: 200px;
  max-width: 250px;
}

.notification-section-dashboard {
  min-width: 200px !important;
  max-width: 250px !important;
}

.beneficiary-wallet-input {
  color: black;
  max-width: 250px;
}

.reminder-icon {
  width: 65%;
  max-width: 200px;
  min-width: 100px;
}

.will3-agreement {
    text-align: left;
    margin: auto;
    margin-bottom: 20px;
    min-width: 200px;
    max-width: 550px;
    color: black;
}

.optional-notification {
  font-size: 10px;
  color: #0B63BC;
  vertical-align: top;
  margin-left: 4px;
}

.required-notification {
  font-size: 10px;
  color: red;
  vertical-align: top;
  margin-left: 4px;
}

.existing-will3-section {
  border: 1px solid black; /* rgba(255, 255, 255, 0.8); */
  padding: 20px 0px;
  margin: 0px auto 40px auto;
  width: 60%;
  color: black;
  background-color: rgba(255, 255, 255, 0.05);
}

th {
  font-weight: bold !important;
  font-size: medium;
}

.step-card {
  /* width: 200px; */
  display: inline-block;
  background: transparent !important;
  /* border: 0 !important; */
  color: black !important;
  text-align: center !important;
  vertical-align: top;
  padding: 10px !important;
  border-radius: 0px !important;
  min-height: 300px;
}

.step-card-image {
  width: auto !important;
  height: 100px !important;
  /* border: 0 !important; */
  text-align: center !important;
  vertical-align: top;
  margin-top: 20px !important;
  display: inline !important;
}

.step-card h5 {
  font-weight: bold;
}

.create-will3-background {
  width: 100%;
  margin: auto;
  padding: 150px;
  background: lightblue;
  width: 100%; 
  height: 100%;
  /* background-image: url("./assets/climber.jpg"); */
  background-repeat: no-repeat;
  filter: brightness(30%);
  background-size: cover;
  min-height: 400px !important;
}

.how-it-works-image {
  width: 100% !important;
}

.module {
  position: relative;
}

.module::before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  max-width: 100%;
  height: 100%;
  background-color: rgb(242, 242, 242);
  background-image: url("./assets/landing-bg-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: brightness(30%); */
  min-height: 800px;
}

.add-rule-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  width: 150px !important;
  height: 40px !important;
  background: #0067C6 !important;
  border: 1px solid #009BDE !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  color: white !important;
}

.add-rule-button:hover {
  color: white !important;
}

.landing-page-top-row {
  height: 600px;
}

.module-inside {
  /* This will make it stack on top of the ::before */
  width: 90%; /* Can be in percentage also. */
  height: auto;
  padding-top: 140px;
  position: relative;
  margin: auto;
}

.module-inside-prerelease {
  /* This will make it stack on top of the ::before */
  width: 80%; /* Can be in percentage also. */
  height: auto;
  margin: 0 auto;
  padding-top: 140px;
  position: relative;
}

.create-will3-header {
  position: relative;
}

.create-will3-header::before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; height: 100%;
  /* background-image: url("./assets/climber.jpg"); */
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  /* filter: brightness(30%); */
  background-size: cover;
  min-height: 310px !important;
  color: #000000;
}

.create-will3-header-inside {
  /* This will make it stack on top of the ::before */
  width: 80%; /* Can be in percentage also. */
  height: auto;
  margin: 0 auto;
  padding-top: 124px;
  position: relative;
}

.ant-notification-bottomRight {
  cursor: pointer;
}

.create-will3-header-image {
  max-height: 500px;
  padding: 0px;
  margin: auto !important;
}

.create-will3-header-title-text {
  margin-top: 48px;
  margin-bottom: 24px;
  font-weight: bold;
  text-align: left;
}

.create-will3-subtitle {
  text-align: left !important;
  text-transform: uppercase;
  background: linear-gradient(271.16deg, #470FE9 -5.86%, rgba(186, 13, 247, 0.49) 108.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.create-will3-description {
  width: 100%;
  min-width: 400px;
  max-width: 900px;
  text-align: left;
  margin: auto;
  margin-top: 20px;
  font-weight: 300;
}

.create-will3-section {
  width: 85%;
  margin: auto; 
  padding: 60px 0px 60px 0px;
  max-width: 1000px;
}

.create-will3-steps {
  margin-top: 36px;
}

.create-will3-step {
  font-weight: 300;
  text-align: left;
  margin: 0;
}

.first-create-will3-section {
  /* padding-top: 100px; */
}

.create-will3-asset-section {
  background: rgb(238, 245, 255);
}

.create-will3-disbursement-section {
}

.create-will3-notification-section {
  background: rgb(238, 245, 255);
}

.create-will3-blue-section {
  background: #EBF7FC;
}

.social-icon {
  margin: 6px;
  height: 50px;
}


.ant-steps-item-container {
  color: black;
}

.ant-collapse-item {
  background-color: white;
  border-bottom: 1px solid white !important;
}

.ant-card {
  background: transparent !important;
}

.ant-card-head-title {
  color: black;
}

.ant-btn {
  border: 1px solid black;
}

.ant-row div {
  color: black;
}

.account-master {
  position: fixed;
  right: 0;
}

.ant-btn-primary {
  background-color: white !important;
  border: 0 !important;
}

.ant-btn-primary span {
  color: black !important;
}

.account-master a {
  color: black !important;
  font-size: 24px;
}

.account-master svg {
  margin-bottom: 8px;
}

.empty-cell {
  opacity: 0;
  border: 0;
}

.approve-all-cell {
  border-radius: 0px 0px 12px 12px;
  border: 0;
  background: transparent !important;
  --bs-table-accent-bg: none !important;
}

.approve-all-link {
  color: rgb(44, 170, 217) !important;
  text-decoration: underline;
  margin: auto;
  width: max-content;
  margin-bottom: 0;
}

.cancel-will3-button {
  color: white !important;
  margin-top: 20px;
  background-color: red !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25) !important;
  height: auto;
  padding: 28;
}

.connect-wallet-button-landing {
  color: white !important;
  margin-top: 20px;
  background-color: #07014F !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.25) !important;
  height: auto;
  padding: 14;
}

td {
  vertical-align: middle;
}

.how-it-works-div {
  margin: 0px 0px;
  padding: 24px 0px 100px 0px;
  background-color: #E7EFF2;
}

.how-it-works {
  width: 100% !important;
}

.how-it-works, p {
    font-size: 15px;
}

.will3-italics {
  font-style: italic;
  font-weight: normal;
  margin: auto;
  margin-bottom: 20px;
  min-width: 200px;
  max-width: 500px;
  color: black;
}

.will3-checkbox {
  max-width: 350px;
  margin: auto;
  margin-bottom: 80px;
  margin-top: 72px;
  font-weight: normal;
}

.dashboard-table {
  width: 100%;
  margin: 36px auto 48px auto;
  text-align: left;
}

.dashboard-table-header {
  font-weight: 500;
}

.dashboard-table-entry {
  padding: 18px;
  border: 0.5px solid #07014F;
  border-radius: 10px;
  margin: 6px auto;
}

.left-align {
  text-align: left !important;
}

.footer-link {
  color: #0568C3 !important;
  font-weight: 500;
}

.step-description {
  color: #00000080;
  width: 75%;
  min-width: 50%;
  margin: auto;
}

.go-to-create-will3 {
  border: 1px solid #07014F !important;
  color: #07014F !important;
  background-color: #EBF7FC !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  height: 48px !important;
}

.app-step-subtitle {
  width: 80%;
  min-width: 300px;
  margin: auto;
  margin-bottom: 48px;
  color: blue;
  color: #A4A4A5;
}

.start-now-button {
  background: linear-gradient(0deg, #07014F, #07014F), linear-gradient(0deg, #009BDE, #009BDE);
  color: white;
}

.landing-page-image {
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: contain;
  max-height: 600px;
}


td {
  background: white !important;
}

tr {
  vertical-align: middle;
}

thead {
  background: #009BDE;
  color: white;
  height: 55px;
  margin: auto;
}

Table {
  /*
  border: 2px solid #009BDE !important;
  background: #009BDE;
  */
  border-radius: 20px 20px 0px 0px !important;  
}

.create-section-description {
  min-width: 500px;
  max-width: 1000px;
  margin: 0px 0px 44px 0px;
  font-weight: 400;
}

.blue-line-divider {
  width: 150px !important;
  height: 5px !important;
  background-color: #009BDE;
  margin-bottom: 42px;
  text-align: center;
  display: inline-flex;
}

.dashboard-navigation-section-header {
  font-size: 12px;
}

.landing-page-left {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.create-page-left {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.landing-page-right {
  display: none !important;
}

.create-page-right {
  display: none !important;
}

@media (min-width: 1100px) {

  /* your conditional / responsive CSS inside this condition */

  .module-inside {
    width: 80%;
  }

  .will3-agreement {
    max-width: 1000px;
  }

  .will3-italics {
    max-width: 1000px;
  }

  .will3-checkbox {
    max-width: 1000px;
  }

  .beneficiary-wallet-input{
    color: black;
    min-width: 250px !important;
  }
  
  .dashboard-table {
    width: 80% !important;
  }
  
  tbody {
    border-top: 0px !important;
  }

  .will3-dashboard-section {
    width: 90% !important;
    padding: 0;
  }

  .how-it-works-image {
    width: 75% !important;
  }

  .how-it-works { 
    width: 80% !important;
    margin: auto !important;
  }

  .create-will3-section {
    width: 80%;
  }

  .step-card {
    /* width: 275px; */
    text-align: center !important;
  }

  .dashboard-navigation-section-header {
    font-size: 15px;
    font-weight: 700;
    color: #07014F;
  }

  .dashboard-header-icon {
    height: 18px;
    margin: auto !important;
    margin-right: 8px !important;
    margin-bottom: 2px !important;
    display: inline-block !important;
  }

  .dashboard-navigation-section-header-selected {
    padding: 10px 24px !important;
  }  


  .landing-page-left {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .create-page-left {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .landing-page-right {
    display: block !important;
  }

  .create-page-right {
    display: block !important;
    margin: auto;
  }

  .select-asset-dropdown {
    min-width: 100px;
  }
}

.block-increase-input {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.will3-dashboard-block-increase {
  width: 100%;
  /* background: rgb(238, 245, 255); */
  background: white;
  width: 100%; 
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 400px !important; */
}

.will3-dashboard-section {
  width: 80%;
  padding: 36px 0 28px 0;
  margin: auto;
  text-align: left;
}

.will3-page-footer {
  /*
  display: block;
  bottom: 0;
  margin: auto;
  width: 100%;
  position: fixed;
*/
  color: black !important;
  background: url("./assets/footer-bg.png") !important; 
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* background-color: #F3F3F3 !important; */
  /* margin-top: 120px; */
}

footer a {
  color: black !important;
  font-weight: 400;
  text-align: left;
}

footer span {
  color: black !important;
  font-weight: 400;
  text-align: left;
}

.faded-section {
  opacity: 0.25;
  pointer-events: none;
  background-color: gray;
}

.disabled-section {
  height: 460px !important;
  width: 100% !important;
  background-color: black;
  display: absolute;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  opacity: .5;
}

.will3-page-footer-create-will3 {
  margin-top: 0px !important;
}

.disclaimer-section {
  width: 60%;
  margin: auto;
  margin-top: 140px;
  text-align: left;
  margin-bottom: 200px;
}

.create-will3-agreement-section {
  padding: 54px 0px 54px 0px;
}

.create-section-header-title {
  font-weight: bold;
  margin-top: 28px;
}

.manual-disburse-page {
  background-color: white;
}

.page-header {
  position: fixed;
  top: 0;
  background: #EBF7FC;
  border-bottom: 1px solid #333333;
  width: 100%;
  margin-bottom: 1000px;
  z-index: 1;
}

.page-header-link {
  cursor: pointer;
  margin: 20px 0px 0px 0px;
}

.disclaimer-section-header {
  font-weight: bold;
  text-align: center;
}

.dashboard-header {
  position: relative;
  background-color: blue !important;
}

.dashboard-header::before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; height: 100%;
  /* background-image: url("./assets/climber.jpg"); */
  background-color: #00305E;
  background-repeat: no-repeat;
  /* filter: brightness(30%); */
  background-size: cover;
  min-height: 260px !important;
}

.dashboard-header-inside {
  /* This will make it stack on top of the ::before */
  width: 80%; /* Can be in percentage also. */
  height: auto;
  margin: 0 auto;
  padding-top: 140px;
  margin-bottom: 100px;
  position: relative;
  color: white !important;
  font-weight: bold;
}

.top-bar-links {
  margin: 0px 16px;
  display: inline-flex;
  /* text-decoration: underline; */
}

.black-recolor {
  color: black !important;
}

.ant-steps-icon .ant-steps-finish-icon {
  display: inline-flex;
}

.app-steps {
  margin-bottom: 40px !important;
}

.connect-wallet-button {
  background-color: #0B63BC !important;
  color: white !important;
}

.connect-wallet-button-disabled {
  background-color: transparent !important;
  color: gray !important;
  border: 1px solid gray !important;
}

#particle-screen {
  height: 50px;
  width: 100%;
  position: fixed;
  pointer-events: none;
}

.front-page-header-title {
  margin-top: 12;
  margin-bottom: 0;
  font-weight: bolder !important;
  color: #FFFFFF;
  font-size: 44px;
}

.manual-disburse-text {
  margin-bottom: 36px;
}

.dashboard-header-icon {
  display: none;
}

.dashboard-navigation-section {
  background: white;
  border: 0.5px solid #0067C6 !important;
  padding-top: 100px; /* 140px */
}

.dashboard-navigation-section-header-container {
  margin-bottom: 28px !important;
}

.dashboard-navigation-section-header-selected {
  background: #0067C6 !important;
  border: 0.5px solid #0067C6;
  border-radius: 10px;
  color: white;
  padding: 6px 10px;
}

.dashboard-navigation-section-header:hover {
  color: unset !important;
}

.dashboard-navigation-section-header-selected:hover {
  color: white !important;
}

.dashboard-section-master {
  background-color: #CCDEE5 !important;
}

.dashboard-section-individual {
  width: calc(98% - 10px) !important;
  height: calc(98% - 10px) !important;
  background-color: white;
}

.app-steps-logged-in {
  width: 100%;
  background: #F2F2F2;
  padding: 160px 0px 80px 0px;
}

.app-steps-not-logged-in {
  width: 100%;
  background: #F2F2F2;
  padding: 160px 0px 80px 0px;
}

.ant-select-arrow {
  color: #999999 !important;
}

.select-asset-dropdown {
  min-width: 120px;
  text-align: left;
}